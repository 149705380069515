import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  title: string;
  description: string;
  code: string;
  discount: string;
  valid_from: any;
  valid_to: any;
  id: any;
  min_cart_value: string;
  max_cart_value: string;
  data: any;
  blankData: {};
  newData: {
    attributes: {
      title: string;
      description: string;
      code: string;
      valid_from: string;
      valid_to: string;
      discount_type: string;
      discount: string;
      min_cart_value: string;
      max_cart_value: string;
      id: any;
    };
  };
  token: string;
  discount_type: string;
  isVisible: boolean;
  isRefreshing: boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
}

export default class CouponcodegeneratorController extends BlockComponent<
  Props,
  S,
  SS
> {
  arrayholder: any[];
  validationApiCallId: string = "";
  addCouponApiCallId: any;
  getCouponApiCallId: any;
  showCouponApiCallId: any;
  DeleteApiCallId: any;
  updateCouponApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    this.state = {
      title: "",
      description: "",
      discount: "",
      code: "",
      id: 0,
      valid_from: new Date(),
      valid_to: new Date(),
      min_cart_value: "",
      max_cart_value: "",
      blankData: {},
      data: [],
      newData: {
        attributes: {
          title: "",
          description: "",
          code: "",
          valid_from: "",
          valid_to: "",
          discount_type: "",
          discount: "",
          min_cart_value: "",
          max_cart_value: "",
          id: 0
        }
      },
      // newData: {
      //   attributes: {},
      // },
      token: "",
      discount_type: "Flat",
      isVisible: false,
      isRefreshing: false
    };
    this.arrayholder = [];

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if ( this.isPlatformWeb() === false) {
      const item = this.props.navigation.state.params.editDetail;
      if (item) {
        this.setState({
          id: item.id,
          title: item.title,
          description: item.description,
          code: item.code,
          valid_from: item.valid_from,
          valid_to: item.valid_to,
          discount_type: item.discount_type,
          discount: item.discount,
          min_cart_value: item.min_cart_value,
          max_cart_value: item.max_cart_value
        });
      }
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addCouponApiCallId != null &&
      this.addCouponApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          data: responseJson.data
        });
        this.getListRequest(this.state.token);
        this.setState({ isRefreshing: false });
        this.arrayholder = responseJson.data;
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCouponApiCallId != null &&
      this.getCouponApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          data: responseJson.data
        });
        this.props.navigation.navigate("Couponcodetable", {
          listData: this.state.data
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        //alert(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.updateCouponApiCallId != null &&
      this.updateCouponApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.getListRequest(this.state.token);
        // this.setState({
        //   data: responseJson.data,
        // });
        // // this.getListRequest(this.state.token);
        // this.setState({ isRefreshing: false});
        // this.props.navigation.navigate("Couponcodetable", {
        //   listData: responseJson.data,
        // });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  };

  // Customizable Area Start
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
  createCoupon() {
    this.addCoupon();
  }
  addCoupon(): boolean {
    if (
      this.isStringNullOrBlank(this.state.title) ||
      this.isStringNullOrBlank(this.state.description) ||
      this.isStringNullOrBlank(this.state.discount) ||
      this.isStringNullOrBlank(this.state.code) ||
      this.isStringNullOrBlank(this.state.valid_from) ||
      this.isStringNullOrBlank(this.state.valid_to) ||
      this.isStringNullOrBlank(this.state.min_cart_value) ||
      this.isStringNullOrBlank(this.state.max_cart_value)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.couponApiContentType,
      token: this.state.token
    };
    const attrs = {
      title: this.state.title,
      description: this.state.description,
      discount: this.state.discount,
      code: this.state.code,
      valid_from: this.state.valid_from,
      valid_to: this.state.valid_to,
      min_cart_value: this.state.min_cart_value,
      max_cart_value: this.state.max_cart_value,
      discount_type: this.state.discount_type.toLowerCase()
    };
    // const data = {
    //   attributes: attrs,
    // };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addCouponApiCallId = requestMessage.messageId;
    const httpBody = {
      data: attrs,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createCouponAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isRefreshing: true });
    return true;
  }
  changeState = (objectID: string, value: any = null) => {
    switch (objectID) {
      case "txtInputTitle":
        this.setState({ title: value });
        break;
      case "txtInputDescription":
        this.setState({ description: value });
        break;
      case "txtInputCode":
        this.setState({ code: value });
        break;
      case "txtInputDiscount":
        this.setState({ discount: value });
        break;
      case "txtInputMincart":
        this.setState({ min_cart_value: value });
        break;
      case "txtInputMaxcart":
        this.setState({ max_cart_value: value });
        break;
      default:
        console.log("changeState::Not Confifured for " + objectID);
    }
  };
  updateCoupon(Id: any) {
    if (
      this.isStringNullOrBlank(this.state.title) ||
      this.isStringNullOrBlank(this.state.description) ||
      this.isStringNullOrBlank(this.state.discount) ||
      this.isStringNullOrBlank(this.state.code) ||
      this.isStringNullOrBlank(this.state.valid_from) ||
      this.isStringNullOrBlank(this.state.valid_to) ||
      this.isStringNullOrBlank(this.state.min_cart_value) ||
      this.isStringNullOrBlank(this.state.max_cart_value)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.couponApiContentType,
      token: this.state.token
    };
    const attrs = {
      title: this.state.title,
      description: this.state.description,
      discount: this.state.discount,
      code: this.state.code,
      valid_from: this.state.valid_from,
      valid_to: this.state.valid_to,
      min_cart_value: this.state.min_cart_value,
      max_cart_value: this.state.max_cart_value,
      discount_type: this.state.discount_type.toLowerCase()
    };
    // const data = {
    //   attributes: attrs,
    // };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCouponApiCallId = requestMessage.messageId;
    // const httpBody = {
    //   data: data,
    // };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponAPiEndPoint + "/" + `${Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(attrs)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchCouponAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isVisible: false, isRefreshing: false });
    return true;
  }
  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.couponApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // const otpAuthTkn = requestMessage.getData(
    //   getName(MessageEnum.AuthTokenDataMessage)
    // );
    this.getCouponApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeCouponGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  navigateToTable() {
    this.getListRequest(this.state.token);
  }
  // Customizable Area Start
  // Customizable Area End
}
