import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  Platform,
  SafeAreaView,
  Modal,
  FlatList,
  RefreshControl
} from "react-native";
// Customizable Area End

import CouponcodetableController, {
  Props
  //configJSON,
} from "./CouponcodetableController";

export default class Couponcodetable extends CouponcodetableController {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  getList(item: any) {
    const value = item.item.attributes;
    const Id = item.item.attributes.id;
    return (
      <View style={styles.tableBox}>
        <Text style={styles.infoText}>
          <Text style={styles.labelText}>Title:{"  "}</Text>
          {value.title}
        </Text>
        <Text style={styles.infoText}>
          <Text style={styles.labelText}>Description:{"  "}</Text>
          {value.description}
        </Text>
        <Text style={styles.infoText}>
          <Text style={styles.labelText}>Code:{"  "} </Text>
          {value.code}
        </Text>
        <Text style={styles.infoText}>
          <Text style={styles.labelText}>Discount Type: {"  "}</Text>
          {value.discount_type}
        </Text>
        <TouchableOpacity
          style={styles.viewBtn}
          testID="ShowCoupon"
          onPress={() => this.showDetail(Id)}
        >
          <Text style={styles.viewBtnText}>View</Text>
        </TouchableOpacity>
      </View>
    );
  }

  render() {
    const data = this.props.navigation.state.params.listData;
    const Id = this.state.newData.attributes.id;

    // let value = datail.attributes;

    return (
      //Merge Engine DefaultContainer
      <SafeAreaView style={styles.defaultContainer}>
        <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
          <TouchableWithoutFeedback
            onPress={() => {
              this.hideKeyboard();
            }}
          >
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <View style={styles.innerContainer}>
              <FlatList
                data={data}
                renderItem={item => this.getList(item)}
                extraData={this.state}
                keyExtractor={(item: any) => item.id}
                stickyHeaderIndices={[0]}
                refreshControl={
                  <RefreshControl refreshing={this.state.isRefreshing} />
                }
              />
              <View style={styles.modalBox}>
                <Modal
                  animationType={"fade"}
                  transparent={false}
                  visible={this.state.isVisible}
                >
                  {/*All views of Modal*/}
                  <View style={styles.modal}>
                    <View style={styles.buttonTop}>
                      <TouchableOpacity
                        style={styles.addMore}
                        testID="navigateToUpdateCoupon"
                        onPress={() =>
                          this.navigateToCoupon(this.state.newData.attributes)
                        }
                      >
                        <Text style={styles.add}>Update</Text>
                      </TouchableOpacity>
                    </View>
                    <ScrollView>
                      <Text style={styles.infoText}>
                        <Text style={styles.labelText}>Title:{"  "}</Text>
                        {this.state.newData.attributes.title}
                      </Text>
                      <Text style={styles.infoText}>
                        <Text style={styles.labelText}>Description:{"  "}</Text>
                        {this.state.newData.attributes.description}
                      </Text>
                      <Text style={styles.infoText}>
                        <Text style={styles.labelText}>Code:{"  "} </Text>
                        {this.state.newData.attributes.code}
                      </Text>
                      <Text style={styles.infoText}>
                        <Text style={styles.labelText}>Valid From:{"  "} </Text>
                        {this.state.newData.attributes.valid_from}
                      </Text>
                      <Text style={styles.infoText}>
                        <Text style={styles.labelText}>Valid To:{"  "} </Text>
                        {this.state.newData.attributes.valid_to}
                      </Text>
                      <Text style={styles.infoText}>
                        <Text style={styles.labelText}>
                          Discount Type: {"  "}
                        </Text>
                        {this.state.newData.attributes.discount_type}
                      </Text>
                      <Text style={styles.infoText}>
                        <Text style={styles.labelText}>Discount: {"  "}</Text>
                        {this.state.newData.attributes.discount}
                      </Text>
                      <Text style={styles.infoText}>
                        <Text style={styles.labelText}>
                          Min Cart value: {"  "}
                        </Text>
                        {this.state.newData.attributes.min_cart_value}
                      </Text>
                      <Text style={styles.infoText}>
                        <Text style={styles.labelText}>
                          Max Cart value: {"  "}
                        </Text>
                        {this.state.newData.attributes.max_cart_value}
                      </Text>
                    </ScrollView>
                    {/* <Button
                      title="Click To Close Modal"
                      onPress={() => {
                        this.setState({ isVisible: !this.state.isVisible });
                      }}
                    /> */}
                    <View style={styles.buttonBox}>
                      <TouchableOpacity
                        style={[styles.viewBtn, styles.viewBtnWidth]}
                        onPress={() => this.deleteRecord(Id)}
                        testID="deleteCoupon"
                      >
                        <Text style={styles.viewBtnText}>delete</Text>
                      </TouchableOpacity>
                      <TouchableOpacity
                        style={[styles.viewBtnWidth, styles.closeBtn]}
                        testID="closeModal"
                        onPress={() => {
                          this.setState({ isVisible: !this.state.isVisible });
                        }}
                      >
                        <Text style={styles.closeBtnText}>Close</Text>
                      </TouchableOpacity>
                    </View>
                  </View>
                </Modal>
              </View>
            </View>
            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </SafeAreaView>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  addMore: {
    backgroundColor: "blue",
    marginBottom: 10,
    width: 80,
    height: 40,
    display: "flex",
    justifyContent: "center",
    borderRadius: 4
  },
  buttonTop: {
    display: "flex",
    alignSelf: "flex-end",
    alignItems: "flex-end",
    justifyContent: "flex-end"
  },
  add: {
    fontSize: 16,
    color: "#fff",
    textAlign: "center"
  },
  viewBtnWidth: {
    width: "48%"
  },
  closeBtn: {
    backgroundColor: "#fff",
    paddingVertical: 8,
    borderRadius: 4,
    marginTop: 10,
    borderColor: "#ccc",
    borderWidth: 1
  },
  buttonBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  modalBox: {
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: "#fff"
  },
  modal: {
    width: "80%",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ccc",
    marginTop: 80,
    marginLeft: 40,
    padding: 15
  },
  defaultContainer: {
    height: "99%",
    width: "100%"
  },
  innerContainer: {
    marginBottom: 30
  },
  viewBtn: {
    backgroundColor: "blue",
    paddingVertical: 8,
    borderRadius: 4,
    marginTop: 10,
    borderWidth: 1,
    borderColor: "blue"
  },
  viewBtnText: {
    color: "#fff",
    textAlign: "center",
    fontSize: 16
  },
  closeBtnText: {
    color: "#000",
    textAlign: "center",
    fontSize: 16
  },
  tableBox: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "#ccc",
    padding: 15,
    marginVertical: 10
  },
  infoText: {
    fontSize: 16,
    marginVertical: 4
  },
  labelText: {
    fontWeight: "bold"
  },
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: Platform.OS === "web" ? "75%" : "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff"
  },
  title: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  body: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  bgPasswordContainer: {
    flexDirection: "row",
    backgroundColor: "#00000000",
    marginBottom: 16,
    borderBottomWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    padding: 10,
    borderWidth: Platform.OS === "web" ? 0 : 1
  },
  bgMobileInput: {
    flex: 1
  },
  showHide: {
    alignSelf: "center"
  },
  imgShowhide: Platform.OS === "web" ? { height: 30, width: 30 } : {}
});
// Customizable Area End
