Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET"
exports.httpPostMethod = "POST"
exports.httpPutMethod = "PUT"

exports.ordersApiContentType = "application/json";
exports.ordersAPiEndPoint = "bx_block_order_management/orders";
exports.rateAPiEndPoint = "bx_block_catalogue/reviews";


// Customizable Area End