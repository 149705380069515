// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'

import DeliveryEstimator from "../../blocks/DeliveryEstimator/src/DeliveryEstimator";
import AdHocReporting from "../../blocks/AdHocReporting/src/AdHocReporting";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import PushNotifications from "../../blocks/PushNotifications/src/PushNotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Sms from "../../blocks/Sms/src/Sms";
import Notifications from "../../blocks/Notifications/src/Notifications";
import Location from "../../blocks/Location/src/Location";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import Scheduling from "../../blocks/Scheduling/src/Scheduling";
import LiveChat from "../../blocks/LiveChat/src/LiveChat";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Settings from "../../blocks/Settings/src/Settings";
import Fedexintegration from "../../blocks/fedexintegration/src/Fedexintegration";
import FedexintegrationOrderTracking from "../../blocks/fedexintegration/src/FedexintegrationOrderTracking";
import StripeIntegration from "../../blocks/StripeIntegration/src/StripeIntegration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Geofence from "../../blocks/Geofence/src/Geofence";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm";
import Interactivefaqs from "../../blocks/interactivefaqs/src/Interactivefaqs";
import AddInteractivefaqs from "../../blocks/interactivefaqs/src/AddInteractivefaqs";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Share from "../../blocks/Share/src/Share";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ShoppingCart from "../../blocks/ShoppingCart/src/ShoppingCart";
import EmailLists from "../../blocks/EmailLists/src/EmailLists";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Sorting from "../../blocks/sorting/src/Sorting";
import PosIntegration from "../../blocks/PosIntegration/src/PosIntegration";
import Gallery from "../../blocks/Gallery/src/Gallery";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import Search from "../../blocks/Search/src/Search";
import TargetedFeed from "../../blocks/TargetedFeed/src/TargetedFeed";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Payments from "../../blocks/Payments/src/Payments";
import DiscountsOffers from "../../blocks/DiscountsOffers/src/DiscountsOffers";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView";
import PhotoLibrary from "../../blocks/PhotoLibrary/src/PhotoLibrary";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import Couponcodetable from "../../blocks/couponcodegenerator/src/Couponcodetable";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";


const routeMap = {
  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  DeliveryEstimator: {
    component: DeliveryEstimator,
    path: '/DeliveryEstimator'
  },
  AdHocReporting: {
    component: AdHocReporting,
    path: '/AdHocReporting'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  PushNotifications: {
    component: PushNotifications,
    path: '/PushNotifications'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Sms: {
    component: Sms,
    path: '/Sms'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  OrderSummary: {
    component: OrderSummary,
    path: '/OrderSummary'
  },

  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  LiveChat: {
    component: LiveChat,
    path: '/LiveChat'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Settings: {
    component: Settings,
    path: '/Settings'
  },
  Fedexintegration: {
    component: Fedexintegration,
    path: '/Fedexintegration'
  },
  FedexintegrationOrderTracking: {
    component: FedexintegrationOrderTracking,
    path: '/FedexintegrationOrderTracking'
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  Geofence: {
    component: Geofence,
    path: '/Geofence'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  Interactivefaqs: {
    component: Interactivefaqs,
    path: '/Interactivefaqs'
  },
  AddInteractivefaqs: {
    component: AddInteractivefaqs,
    path: '/AddInteractivefaqs'
  },
  

  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  Share: {
    component: Share,
    path: '/Share'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  ShoppingCart: {
    component: ShoppingCart,
    path: '/ShoppingCart'
  },
  EmailLists: {
    component: EmailLists,
    path: '/EmailLists'
  },  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  PosIntegration: {
    component: PosIntegration,
    path: '/PosIntegration'
  },
  Gallery: {
    component: Gallery,
    path: '/Gallery'
  },

  PaymentAdmin: {
    component: PaymentAdmin,
    path: '/PaymentAdmin'
  },

  Search: {
    component: Search,
    path: '/Search'
  },

  TargetedFeed: {
    component: TargetedFeed,
    path: '/TargetedFeed'
  },

  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },

  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Splashscreen: {
    component: Splashscreen,
    path: '/Splashscreen'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },

  DiscountsOffers: {
    component: DiscountsOffers,
    path: '/DiscountsOffers'
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: '/Notificationsettings'
  },
  OrderDetailView: {
    component: OrderDetailView,
    path: '/OrderDetailView'
  },
  PhotoLibrary: {
    component: PhotoLibrary,
    path: '/PhotoLibrary'
  },

  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  Couponcodegenerator: {
    component: Couponcodegenerator,
    path: '/Couponcodegenerator'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },

  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },






  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
