import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  title: string;
  description: string;
  code: string;
  discount: string;
  valid_from: any;
  valid_to: any;
  id: any;
  min_cart_value: string;
  max_cart_value: string;
  data: any;
  blankData: {};
  newData: {
    attributes: {
      title: string;
      description: string;
      code: string;
      valid_from: string;
      valid_to: string;
      discount_type: string;
      discount: string;
      min_cart_value: string;
      max_cart_value: string;
      id: any;
    };
  };
  token: string;
  discount_type: string;
  isVisible: boolean;
  isRefreshing: boolean;
  // Customizable Area End
}

export interface SS {
  id: any;
}

export default class CouponcodetableController extends BlockComponent<
  Props,
  S,
  SS
> {
  arrayholder: any[];
  validationApiCallId: string = "";
  addCouponApiCallId: any;
  getCouponApiCallId: any;
  showCouponApiCallId: any;
  DeleteApiCallId: any;
  updateCouponApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.navigateToCoupon = this.navigateToCoupon.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      title: "",
      description: "",
      discount: "",
      code: "",
      id: 0,
      valid_from: new Date(),
      valid_to: new Date(),
      min_cart_value: "",
      max_cart_value: "",
      blankData: {},
      data: [],
      newData: {
        attributes: {
          title: "",
          description: "",
          code: "",
          valid_from: "",
          valid_to: "",
          discount_type: "",
          discount: "",
          min_cart_value: "",
          max_cart_value: "",
          id: 0
        }
      },
      token: "",
      discount_type: "Flat",
      isVisible: false,
      isRefreshing: false
    };
    this.arrayholder = [];

    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: String, message: Message) {
    runEngine.debugLog("Coupon Code", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.showCouponApiCallId != null &&
      this.showCouponApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if ( !responseJson.errors && responseJson.data) {
        this.setState({
          newData: responseJson.data
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCouponApiCallId != null &&
      this.getCouponApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if ( !responseJson.errors && responseJson.data) {
        this.setState({
          data: responseJson.data
        });

        this.props.navigation.navigate("Couponcodetable", {
          listData: this.state.data
        });
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.DeleteApiCallId != null &&
      this.DeleteApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.getListRequest(this.state.token);
      this.setState({ isVisible: false, isRefreshing: false });
      if ( !responseJson.errors && responseJson.data) {
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }
  }

  // Customizable Area Start
  navigateToCoupon(item: any) {
    this.setState({ isVisible: false });
    this.props.navigation.push("Couponcodegenerator", { editDetail: item });
  }

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.couponApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCouponApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeCouponGet
    );
    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteRecord(Id: any) {
    const header = {
      "Content-Type": configJSON.couponApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    // const otpAuthTkn = requestMessage.getData(
    //   getName(MessageEnum.AuthTokenDataMessage)
    // );
    this.DeleteApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponAPiEndPoint + "/" + `${Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteCouponAPiMethod
    );
    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  showDetail(Id: string) {
    this.setState({ isVisible: true });
    const header = {
      "Content-Type": configJSON.couponApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showCouponApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.couponAPiEndPoint + "/" + `${Id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeCouponGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area Start
  // Customizable Area End
}
