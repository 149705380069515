Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.checkoutStatusMethodType = "GET";
exports.checkoutAPiEndPoint = "/bx_block_payments/hyperpay_payments";
exports.checkoutStatus = "/bx_block_payments/hyperpay_payments/payment_status?checkout_id=";
exports.hyperpayAPIMethodType = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "payments";
exports.labelBodyText = "payments Body";

exports.btnExampleTitle = "CLICK ME";
exports.httpPostType = "POST"
exports.httpGetType = "GET";
exports.razorpayApiContentType = "application/json";
exports.errorOrderNotValid = "Please enter all fields."
exports.title = "Enter Order Id"
exports.razorpayAPiEndPoint = "bx_block_payment_razorpay/razorpays";
exports.ordersAPiEndPoint = "bx_block_order_management/orders";
// Customizable Area End