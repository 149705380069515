Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPatchMethod =  "PATCH";

exports.getNotificationAPIEndPoint = "bx_block_notification_settings/notification_settings";
exports.updateNotificationAPIEndPoint = "bx_block_notification_settings/notification_settings/";
exports.updateNotificationGroupAPIEndPoint = "bx_block_notification_settings/groups/";
exports.updateNotificationSubGroupAPIEndPoint = "bx_block_notification_settings/subgroups/";

exports.notificationApiContentType = "application/json";

// Customizable Area End