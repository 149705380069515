Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "bx_block_catalogue/catalogues";
exports.brandAPiEndPoint = "bx_block_catalogue/brands";
exports.filterbrandAPiEndPoint = "bx_block_filter_items/filtering?q[brand_id]=";
exports.singleCategoryAPiEndPoint = "bx_block_filter_items/filtering?q[category_id]=";
exports.cataloguesAPiEndPoint = "bx_block_filter_items/filtering?";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "filteritems";
exports.labelBodyText = "filteritems Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End